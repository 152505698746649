import { quest, rewards, team, wallet } from "@fscrypto/domain";
import { Avatar, Button, Card, CardContent, Dropdown, Text, Tooltip } from "@fscrypto/ui";
import { Link } from "@remix-run/react";
import { capitalize } from "lodash-es";
import { Check, ChevronDownIcon, CopyIcon, Loader2Icon } from "lucide-react";
import { useState } from "react";
import { useCurrentUser } from "~/features/current-user";
import { EcoPill } from "~/features/ecosystem/eco-pill";
import { useToaster } from "~/features/toasts/toast.machine";
import { useWallets } from "~/features/wallet-management/state/use-wallet";
import config from "~/utils/config";
import { roundRewardsAmount, truncateAddress } from "~/utils/helpers";
import { tracking } from "~/utils/tracking";
import { useClaim } from "../state/use-claim";

interface Props {
  reward: rewards.UserRewards;
  team?: team.Team;
  showHeader?: boolean;
}

export const RewardCard = ({ reward, team, showHeader = false }: Props) => {
  const earn = useClaim(reward.id);
  const user = useCurrentUser();
  const [dropdownSelection, setDropdownSelection] = useState<wallet.Address>();
  const [isConfirm, setConfirm] = useState(false);
  const wallet = useWallets();
  const { isIdle, isErrorClaiming, isClaiming, isPending, paymentRecord, isDone, isTimeout, reset, claim } = earn;

  let wallets = wallet.walletsByChain[reward.chain] ?? [];
  wallets = wallets.filter((w) => w.isPayable);
  const isEmpty = wallets.length === 0;

  function getRewardSource(reward: rewards.UserRewards) {
    if (reward.RewardSourceType === "REWARDS_PROGRAM") {
      return reward.rewardSourceId;
    }
    if (reward.RewardSourceType === "MANUAL") {
      const paymentName =
        reward.manualReward?.payment.name ??
        reward.manualReward?.manifest.name ??
        reward.manualReward?.payment.type ??
        "direct payment";
      return paymentName;
    }
    return reward.quest?.name ?? reward.id;
  }

  const transactionLink = paymentRecord && rewards.RewardsService.generateBlockExplorerLink(paymentRecord);

  return (
    <Card
      variant={isErrorClaiming ? "warning" : "surface"}
      divided={isEmpty || isErrorClaiming || isIdle || isClaiming || isPending || isDone}
      className="bg-subtle"
    >
      {team && showHeader && (
        <div className="flex items-center gap-x-2 p-2">
          <Avatar src={team.avatarUrl} size="sm" alt={team.name} />
          <Text size="xs">{team.name}</Text>
        </div>
      )}
      {!team && user.currentUser && showHeader && (
        <div className="flex items-center gap-x-2 p-2">
          <Avatar src={user.currentUser.avatarUrl} size="sm" alt={user.currentUser.username} />
          <Text size="xs">{user.currentUser?.username}</Text>
        </div>
      )}
      <CardContent padding="md" className="flex flex-col">
        {(isIdle || isDone || isClaiming || isPending) && <Text size="lg">{getRewardSource(reward)}</Text>}
        {isEmpty ? (
          <EmptyWallet network={reward.chain} />
        ) : isConfirm || isClaiming ? (
          <>
            <p className="mb-2">Paying out to</p>
            <PayoutWalletAddress address={dropdownSelection!} />
          </>
        ) : (
          isIdle && (
            <div className="space-y-1 py-3">
              <h3 className="dark:text-neutral-30 text-xs text-neutral-700">{capitalize(reward.chain)} Addresses</h3>
              <Dropdown.Root>
                <Dropdown.Trigger className="w-full">
                  <div className="dark:bg-gray-90 dark:text-gray-40 relative w-full cursor-default rounded-md border bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm focus:outline-none sm:text-sm sm:leading-6">
                    {dropdownSelection ? (
                      <WalletAddress address={dropdownSelection} />
                    ) : (
                      <span className="text-gray-60 block truncate">{"Select a wallet"}</span>
                    )}
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronDownIcon className="text-muted-foreground size-5" aria-hidden="true" />
                    </span>
                  </div>
                </Dropdown.Trigger>
                <Dropdown.Content align="start" className="z-50 w-full min-w-[300px]">
                  {wallets.map((w) => {
                    return (
                      <Dropdown.Item key={w.id} onClick={() => setDropdownSelection(w)}>
                        <WalletAddressDropdownItem address={w} isSelected={dropdownSelection?.id === w.id} />
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Content>
              </Dropdown.Root>
            </div>
          )
        )}
        {isErrorClaiming && <ClaimError />}
        {isPending && <Text>Waiting for transaction to appear on chain</Text>}
        {isTimeout && (
          <Text>
            Timed out waiting for payment to appear. Please try again or create a ticket in Intercom using the purple
            launcher in the bottom left corner of the app.
          </Text>
        )}
      </CardContent>
      <CardContent padding="lg">
        {(isDone || isClaiming || isIdle || isConfirm) && (
          <p className="pb-2 text-xs text-neutral-700 dark:text-neutral-300">
            Rewarded {reward.createdAt.toLocaleString()}
          </p>
        )}
        <div className="flex flex-col items-center justify-between gap-2 md:flex-row md:flex-wrap">
          {/* Nonexisting variants will just default to the primary variant */}
          <EcoPill
            variant={reward.chain as quest.QuestEcosystem}
            className="flex max-h-24 flex-row space-x-1 rounded-full px-3 py-2"
          >
            <Avatar
              src={config.CLOUDINARY_PROJECT_ICON_PATH + `${reward.chain}.svg`}
              size="sm"
              className="bg-transparent"
            />
            <p className="font-semibold">
              {roundRewardsAmount(reward.tokenClaimable)} {reward.currency}
            </p>
          </EcoPill>
          {isIdle && isConfirm === false && (
            <Tooltip content="Please select a wallet" side="top" display={!dropdownSelection}>
              <Button
                variant="primary"
                size="md"
                disabled={reward.kycStatus !== "CLAIMABLE" || !dropdownSelection}
                onClick={() => {
                  setConfirm(true);
                  tracking("claim_reward", "Earn");
                }}
              >
                {reward.kycStatus === "CLAIMABLE" ? "Claim" : "Verify Tax Info to Claim"}
              </Button>
            </Tooltip>
          )}
          {isConfirm && (
            <div className="flex flex-wrap gap-2">
              <Button
                variant="secondary"
                size="md"
                onClick={() => {
                  setConfirm(false);
                  tracking("cancel_claim_reward", "Earn");
                }}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                size="md"
                onClick={() => {
                  setConfirm(false);
                  claim?.(dropdownSelection!.address);
                  tracking("confirm_claim_reward", "Earn");
                }}
              >
                Confirm Claim
              </Button>
            </div>
          )}
          {isClaiming && (
            <Button variant="secondary" size="md">
              <div className="flex items-center gap-2">
                <Loader2Icon className="size-4 animate-spin" /> <p>Claiming</p>
              </div>
            </Button>
          )}
          {isPending && (
            <Button variant="secondary" size="md">
              <div className="flex items-center gap-2">
                <Loader2Icon className="size-4 animate-spin" /> <p>Processing</p>
              </div>
            </Button>
          )}
          {isDone && transactionLink && (
            <a href={transactionLink} target="_blank" rel="noopener noreferrer">
              <Button variant="secondary" size="md">
                View Transaction
              </Button>
            </a>
          )}
          {isErrorClaiming && (
            <Button variant="secondary" size="md" onClick={reset}>
              Cancel
            </Button>
          )}
          {isTimeout && (
            <Button variant="secondary" size="md" onClick={reset}>
              Cancel
            </Button>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

const EmptyWallet = ({ network }: { network: string }) => {
  return (
    <>
      <div className="mb-4 flex items-center justify-between">
        <Text weight="medium" color="contrast">
          Add Address
        </Text>
        <Link to="/settings/addresses" className="text-xs text-blue-500">
          Manage Addresses
        </Link>
      </div>
      <Text className="text-foreground" size="sm">
        Add an {capitalize(network)} address to claim this reward.
      </Text>
    </>
  );
};

const ClaimError = () => {
  return (
    <>
      <div className="mb-4 flex items-center justify-between">
        <Text weight="medium" color="contrast">
          Error
        </Text>
        <Link to="/settings/addresses" className="text-xs text-blue-500">
          Manage Addresses
        </Link>
      </div>
      <Text className="text-foreground" size="sm">
        Failed to claim reward. Please try again. If the problem persist, please create a ticket in Intercom using the
        purple launcher in the bottom left corner of the app.
      </Text>
    </>
  );
};

const WalletAddress = ({ address }: { address: wallet.Address }) => {
  const { notify } = useToaster();
  const hasNickname = address.nickname !== null && address.nickname !== "";

  async function onCopy() {
    await navigator.clipboard.writeText(address.address);
    notify?.({
      type: "success",
      title: "Copied address to clipboard",
      timeout: 1000,
    });
  }

  return (
    <div className="flex flex-wrap items-center gap-2">
      <Avatar src={config.CLOUDINARY_PROJECT_ICON_PATH + `${address.chain}.svg`} size="sm" />
      <Text color="contrast">
        {hasNickname ? address.nickname : truncateAddress(address.address, { left: 6, right: 6, maxLength: 24 })}
      </Text>
      {!hasNickname && <CopyIcon className="size-4 cursor-pointer" onClick={onCopy} />}
    </div>
  );
};

const WalletAddressDropdownItem = ({ address, isSelected }: { address: wallet.Address; isSelected?: boolean }) => {
  const { notify } = useToaster();
  const hasNickname = address.nickname !== null && address.nickname !== "";

  async function onCopy() {
    await navigator.clipboard.writeText(address.address);
    notify?.({
      type: "success",
      title: "Copied address to clipboard",
      timeout: 1000,
    });
  }
  return (
    <div className="flex w-full flex-row items-center justify-between">
      <div className="flex flex-wrap items-center gap-2">
        <Avatar src={config.CLOUDINARY_PROJECT_ICON_PATH + `${address.chain}.svg`} size="sm" />
        <Text color="contrast">
          {hasNickname ? address.nickname : truncateAddress(address.address, { left: 6, right: 6, maxLength: 24 })}
        </Text>
        {!hasNickname && <CopyIcon className="size-4 cursor-pointer" onClick={onCopy} />}
      </div>
      {isSelected && <Check className="text-blue-40 size-4" />}
    </div>
  );
};

const PayoutWalletAddress = ({ address }: { address: wallet.Address }) => {
  const { notify } = useToaster();
  const hasNickname = address.nickname !== null && address.nickname !== "";

  async function onCopy() {
    await navigator.clipboard.writeText(address.address);
    notify?.({
      type: "success",
      title: "Copied address to clipboard",
      timeout: 1000,
    });
  }

  return hasNickname ? (
    <div className="flex flex-row flex-wrap gap-2">
      <div className="flex flex-wrap items-center gap-2">
        <Avatar src={config.CLOUDINARY_PROJECT_ICON_PATH + `${address.chain}.svg`} size="sm" />
        <p className="font-medium">{address.nickname}</p>
      </div>
      <div className="flex flex-wrap items-center gap-2">
        <Text color="contrast">{truncateAddress(address.address, { left: 6, right: 6, maxLength: 24 })}</Text>
        <CopyIcon className="size-4 cursor-pointer" onClick={onCopy} />
      </div>
    </div>
  ) : (
    <div className="flex flex-wrap items-center gap-2">
      <Avatar src={config.CLOUDINARY_PROJECT_ICON_PATH + `${address.chain}.svg`} size="sm" />
      <Text color="contrast">{truncateAddress(address.address, { left: 6, right: 6, maxLength: 24 })}</Text>
      <CopyIcon className="size-4 cursor-pointer" onClick={onCopy} />
    </div>
  );
};
