import * as DialogPrimitive from "@radix-ui/react-dialog";
import { cva } from "class-variance-authority";
import { forwardRef } from "react";
import { Text } from "../text/text";
import { XIcon } from "lucide-react";

export const Drawer: typeof DialogPrimitive.Root = DialogPrimitive.Root;

export const DrawerTrigger = DialogPrimitive.Trigger;

const overlay = cva([
  "fixed z-50 inset-0 bg-black/20 backdrop-blur-sm duration-100",
  "data-[state=open]:animate-in data-[state=open]:fade-in-0",
  "data-[state=closed]:animate-out data-[state=closed]:fade-out-0",
]);

const content = cva(
  [
    "fixed z-50 bg-background translate-x-0 top-1 bottom-1 right-1 left-1 w-full overflow-hidden rounded-lg",
    "max-w-md p-5",
    "data-[state]:duration-250",
    "data-[state=open]:animate-in data-[state=open]:fade-in-0",
    "data-[state=closed]:animate-out data-[state=closed]:fade-out-0",
  ],
  {
    variants: {
      position: {
        right: "sm:left-auto data-[state=open]:slide-in-from-right-[50%] data-[state=closed]:slide-out-to-right-[50%]",
        left: "sm:right-auto data-[data=open]:slide-in-from-left-[50%] data-[state=closed]:slide-out-to-left-[50%]",
      },
    },
    defaultVariants: {
      position: "right",
    },
  },
);

export const DrawerContent: typeof DialogPrimitive.Content = forwardRef(({ children, ...props }, ref) => {
  return (
    <DialogPrimitive.Portal>
      <DialogPrimitive.Overlay className={overlay()} />
      <DialogPrimitive.Content className={content()} ref={ref} {...props}>
        <DialogPrimitive.Close asChild className="absolute right-0 top-0 mr-4 mt-4">
          <XIcon className="size-5 text-content" />
        </DialogPrimitive.Close>
        {children}
      </DialogPrimitive.Content>
    </DialogPrimitive.Portal>
  );
});
DrawerContent.displayName = DialogPrimitive.Content.displayName;

export const DrawerTitle: typeof DialogPrimitive.Title = forwardRef(({ ...props }, ref) => {
  return (
    <DialogPrimitive.Title {...props} ref={ref}>
      <Text color="contrast" weight="medium" className="leading-6">
        {props.children}
      </Text>
    </DialogPrimitive.Title>
  );
});

DrawerTitle.displayName = DialogPrimitive.Title.displayName;

export const DrawerDescription: typeof DialogPrimitive.Description = forwardRef(({ ...props }, ref) => {
  return (
    <DialogPrimitive.Description {...props} ref={ref}>
      <Text as="p" color="content" size="sm">
        {props.children}
      </Text>
    </DialogPrimitive.Description>
  );
});

DrawerDescription.displayName = DialogPrimitive.Description.displayName;
