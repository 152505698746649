import { Slot } from "@radix-ui/react-slot";
import { Text } from "../text/text";
import { cn } from "../utils";
import { navigationMenu } from "./navigation-menu.styles";
import * as Primitive from "@radix-ui/react-navigation-menu";
import { CaretDownIcon } from "@radix-ui/react-icons";

type RootProps = React.ComponentProps<typeof Primitive.Root>;
const Root = ({ className, children, ...props }: RootProps) => (
  <Primitive.Root className={cn(navigationMenu.root(), className)} {...props}>
    {children}
  </Primitive.Root>
);

const List = ({ className, ...props }: React.ComponentProps<typeof Primitive.List>) => (
  <Primitive.List className={cn(navigationMenu.list(), className)} {...props} />
);

const Trigger = ({ className, ...props }: React.ComponentProps<typeof Primitive.Trigger>) => (
  <Primitive.Trigger className={cn(navigationMenu.item(), `group`, className)} {...props} />
);

const NavLink = ({ className, ...props }: React.ComponentProps<typeof Primitive.Link>) => (
  <Primitive.Link className={cn(navigationMenu.item(), className)} {...props} />
);

const Content = ({ className, ...props }: React.ComponentProps<typeof Primitive.Content>) => (
  <Primitive.Content className={cn(navigationMenu.content(), className)} {...props} />
);

const Viewport = ({ className, ...props }: React.ComponentProps<typeof Primitive.Viewport>) => (
  <div className="absolute left-0 top-full flex w-full justify-center">
    <Primitive.Viewport className={cn(navigationMenu.viewport(), className)} {...props} />
  </div>
);

const Caret = () => (
  <CaretDownIcon
    className="text-content relative top-[1px] ml-1 transition-transform duration-200 ease-in group-data-[state=open]:-rotate-180"
    aria-hidden
  />
);

const ListItem = ({
  asChild,
  title,
  className,
  children,
  ...props
}: {
  title: string;
  asChild?: boolean;
  className?: string;
  children?: React.ReactNode;
}) => {
  const Comp = asChild ? Slot : Primitive.Link;
  return (
    <Comp className={cn(navigationMenu.item(), `flex-col items-start`, className)} {...props}>
      <div>
        <Text size="sm" weight="medium" color="contrast">
          {title}
        </Text>
        <Text size="xs" color="muted">
          {children}
        </Text>
      </div>
    </Comp>
  );
};

export const NavigationMenu = {
  Link: Primitive.Link,
  Item: Primitive.Item,
  Root,
  List,
  Trigger,
  NavLink,
  Content,
  Viewport,
  Caret,
  ListItem,
};
