import { Button, Tooltip } from "@fscrypto/ui";
import { AlertTriangleIcon, Gift } from "lucide-react";
import { useRewardsDrawer } from "~/features/earn/state/use-reward-drawer";
import { useRewards } from "~/features/earn/state/use-rewards";

export const RewardDrawerButtonMobile = () => {
  const rewards = useRewards();
  const rewardsDrawer = useRewardsDrawer();
  const { rewardOverview, isError, isLoading } = rewards;
  const isIdle = rewardOverview === undefined;

  if (isError) return null;
  if (isLoading || isIdle) {
    return (
      <Button variant="secondary" disabled size="sm">
        <Gift />
      </Button>
    );
  }
  return (
    <Button variant="secondary" size="sm" className="relative" onClick={() => rewardsDrawer.setOpen(true)}>
      <div className="relative flex flex-row items-center gap-1">
        <Gift />
        {rewardOverview.userRewards.length > 0 && (
          <div className="absolute right-0 top-0 -translate-y-1/2 translate-x-1/2 transform rounded-full bg-red-500 px-1 text-xs text-white">
            {rewardOverview.userRewards.length}
          </div>
        )}
      </div>
    </Button>
  );
};

export const RewardDrawerButtonDesktop = () => {
  const rewards = useRewards();
  const rewardsDrawer = useRewardsDrawer();
  const { rewardOverview, isError, isLoading } = rewards;
  const isIdle = rewardOverview === undefined;

  if (isError) {
    return (
      <Tooltip content="Something went wrong in retrieving rewards" side="bottom">
        <Button variant="secondary" size="sm" disabled>
          <div className="flex flex-row items-center gap-1">
            Rewards
            <AlertTriangleIcon className="text-warning h-4 w-4" />
          </div>
        </Button>
      </Tooltip>
    );
  }
  if (isLoading || isIdle) {
    return (
      <Button variant="secondary" size="sm" disabled>
        Rewards
      </Button>
    );
  }
  return (
    <Button variant="secondary" size="sm" onClick={() => rewardsDrawer.setOpen(true)}>
      <div className="flex flex-row items-center gap-1">
        Rewards
        {rewardOverview.userRewards.length > 0 && (
          <div className="rounded-full bg-red-500 px-1 text-xs text-white">{rewardOverview.userRewards.length}</div>
        )}
      </div>
    </Button>
  );
};
