import { LogoIcon, Text } from "@fscrypto/ui";
import { SiDiscord, SiX } from "@icons-pack/react-simple-icons";
import * as Portal from "@radix-ui/react-portal";
import { Link, useParams } from "@remix-run/react";
import { motion } from "framer-motion";
import { MenuIcon } from "lucide-react";
import { useState } from "react";
import { $path } from "remix-routes";
import { DATA_ACCESS, EARN, RESOURCES, track } from "../utils/shell-values";

export function ShellNavMobile() {
  const [open, setOpen] = useState(false);
  const { ecosystem } = useParams();
  return (
    <div className="md:hidden">
      <button
        onClick={() => setOpen((isOpen) => !isOpen)}
        className="flex text-black dark:text-white"
        aria-label="menu"
        name="menu"
      >
        <MenuIcon />
      </button>

      {open ? (
        <Portal.Root className="fixed inset-0 z-50 overflow-hidden md:hidden">
          {/* Overlay */}
          <div className="bg-black/15 absolute inset-0 backdrop-blur-sm" onClick={() => setOpen(false)} />
          {/* Drawer */}
          <motion.div
            className="absolute inset-y-0 w-5/6 space-y-6 overflow-y-scroll bg-zinc-50 dark:bg-zinc-950"
            initial="closed"
            animate="open"
            transition={{ duration: 0.2, ease: "circOut" }}
            variants={{ closed: { x: "-100%" }, open: { x: 0 } }}
            onClick={() => setOpen(false)}
          >
            <div className="flex h-12 items-center px-4">
              <Link to="/" className=" dark:text-white" onClick={() => track("Click Home")}>
                <LogoIcon />
              </Link>
            </div>

            <nav className=" flex flex-col px-4">
              <Link to={$path("/earn")} className="text-contrast py-3 font-semibold">
                Earn
              </Link>

              <Link to="/grail/aptos/s1" className="text-contrast py-3 font-semibold">
                Grail
              </Link>

              <Link to={$path("/insights/dashboards")} className="text-contrast py-3 font-semibold">
                Insights
              </Link>

              <Link to={$path("/partnerships")} className="text-contrast py-3 font-semibold">
                Partnerships
              </Link>

              <div className="py-4">
                <Text as="h2" weight="semi" color="muted" size="xs" className="py-3">
                  Products
                </Text>
                <ul className="space-y-4">
                  {DATA_ACCESS.map((item) => (
                    <Link
                      key={item.to}
                      to={item.to}
                      className="flex flex-col space-y-1"
                      onClick={() => track(`Click ${item.title}`)}
                      aria-label={`Open ${item.title}`}
                    >
                      <Text data-testid={item.title}>{item.title}</Text>
                      <Text size="xs" color="muted">
                        {item.description}
                      </Text>
                    </Link>
                  ))}
                </ul>
              </div>

              <div className="py-4">
                <Text as="h2" weight="semi" color="muted" size="xs" className="py-3">
                  Earn
                </Text>
                <ul className="space-y-4">
                  {EARN.map((item) => (
                    <Link
                      key={item.title}
                      to={item.to(ecosystem)}
                      className="flex flex-col space-y-1"
                      onClick={() => track(`Click ${item.title}`)}
                      aria-label={`Open ${item.title}`}
                    >
                      <Text data-testid={item.title}>{item.title}</Text>
                      <Text size="xs" color="muted">
                        {item.description}
                      </Text>
                    </Link>
                  ))}
                </ul>
              </div>

              <div className="py-4">
                <Text as="h2" weight="semi" color="muted" size="xs" className="py-3">
                  Resources
                </Text>
                <ul className="space-y-4">
                  {RESOURCES.map((item) => (
                    <a
                      key={item.href}
                      href={item.href}
                      target="_blank"
                      className="flex flex-col space-y-1"
                      onClick={() => track(`Click ${item.title}`)}
                      aria-label={`Open ${item.title}`}
                      rel="noreferrer"
                    >
                      <Text>{item.title}</Text>
                    </a>
                  ))}
                </ul>
              </div>

              <div className="flex space-x-4 py-4">
                <a
                  href="https://twitter.com/flipsidecrypto"
                  target="_blank"
                  rel="noreferrer"
                  className="text-zinc-400"
                  onClick={() => track(`Click Twitter`)}
                  aria-label={`Open Twitter in new tab`}
                >
                  <SiX className="size-4" />
                </a>
                <a
                  href="https://discord.com/invite/ZmU3jQuu6W"
                  target="_blank"
                  rel="noreferrer"
                  className="text-zinc-400"
                  onClick={() => track(`Click Discord`)}
                  aria-label={`Open Discord in new tab`}
                >
                  <SiDiscord className="size-4" />
                </a>
              </div>
            </nav>
          </motion.div>
        </Portal.Root>
      ) : null}
    </div>
  );
}
