import { createSimpleEpic } from "@fscrypto/state-management";
import { type Observable, merge } from "rxjs";
import { currentPlanClient } from "../data/current-plan-client";
import { CurrentPlanUsageLoader } from "~/routes/api/plan-usage";

// Input events, triggers epics
type GetPlan = { type: "CURRENT_PLAN.EPIC.FETCH" };

// Output events, sent to the machine
type GetPlanSuccess = { type: "CURRENT_PLAN.EPIC.FETCH_SUCCESS"; payload: CurrentPlanUsageLoader };
type GetPlanFailure = { type: "CURRENT_PLAN.EPIC.FETCH_FAILURE"; error: Error };

export type OutputEvent = GetPlanSuccess | GetPlanFailure;
type InputEvent = GetPlan;

/**
 * Merges multiple epic functions into a single Observable<OutputEvent>.
 * Merging these epics into one observable allows using a single subject for capturing all events.
 */
export const createEpic = (actions$: Observable<InputEvent>): Observable<OutputEvent> => {
  return merge(getPlanEpic(actions$));
};

const getPlanEpic = createSimpleEpic<GetPlan, CurrentPlanUsageLoader, GetPlanSuccess, GetPlanFailure>({
  ofType: "CURRENT_PLAN.EPIC.FETCH",
  performTask: () => currentPlanClient.getCurrentPlanUsage(),
  onSuccess: (payload) => ({ type: "CURRENT_PLAN.EPIC.FETCH_SUCCESS", payload }),
  onFailure: (error) => ({
    type: "CURRENT_PLAN.EPIC.FETCH_FAILURE",
    error: new Error("Unable to fetch plan"),
  }),
});

// ASYNC

// Add any async calls here
