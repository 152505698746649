import { useActor } from "~/state/hooks";
import { createCurrentUsageActor } from "./actor";

export const useCurrentUsage = () => {
  // You can either useActor and create the actor from the hook, or `useActorFromSystem` to get an existing actor
  const [state, ref] = useActor(() => createCurrentUsageActor("currentPlan-1", {}));

  if (!state || !ref) return {} as const;
  return {
    usage: state.context.usage,
  };
};
