import { Flex, Select, SelectContent, SelectItem, SelectTrigger, SelectValue, Text } from "@fscrypto/ui";
import { Zap } from "lucide-react";
import { Profile } from "@fscrypto/domain/profile";
import { plan as planDomain } from "@fscrypto/domain";
import { useCurrentUser } from "../state/current-user";
import clsx from "clsx";
import { tracking } from "~/utils/tracking";

export const DefaultWarehouseSelect = () => {
  const { profile, userPlan, setDefaultWarehouse } = useCurrentUser();

  // Only show this if the user is on a paid plan
  if (!userPlan || planDomain.isFree(userPlan.plan.code)) {
    return null;
  }

  const disabled = planDomain.isFree(userPlan?.plan.code);
  return (
    <div className={clsx({ "opacity-30": disabled })}>
      <Select
        disabled={planDomain.isFree(userPlan?.plan.code)}
        value={profile?.defaultWarehouseType}
        onValueChange={(val: Profile["defaultWarehouseType"]) => {
          tracking("click_toggle_warehouse", "Settings");
          setDefaultWarehouse?.(val);
        }}
      >
        <SelectTrigger className="bg-background flex w-full" size={"sm"}>
          <SelectValue placeholder="Default warehouse" className="opacity-30" />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="free">
            <Text>Free Warehouse</Text>
          </SelectItem>
          <SelectItem value="paid">
            <Flex alignItems="center">
              <Zap className="size-4 mr-2" />
              <Text>Paid Warehouse</Text>
            </Flex>
          </SelectItem>
        </SelectContent>
      </Select>
    </div>
  );
};
