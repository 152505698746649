import { createActor } from "~/state/actor";
import { type SystemKey, createCurrentPlanMachine, type Options } from "./machine";
import { createEpic } from "./epic";
import { actorSystem } from "~/state";
import { eventBus } from "~/state/events";
import { assign } from "xstate";

export const createCurrentUsageActor = createActor(
  { createMachine: createCurrentPlanMachine, createEpic },
  ({ epic }): Options => ({
    actions: {
      fetchPlanEpic: (ctx) => {
        epic.send({ type: "CURRENT_PLAN.EPIC.FETCH" });
      },
      handleGetPlanFailure: (ctx) => {
        eventBus.send({ type: "TOAST.NOTIFY", notif: { type: "error", title: "Failed to fetch plan." } });
      },
      handleGetPlanSuccess: assign({
        usage: (ctx, event) => event.payload,
      }),
    },
  }),
);

export type Actor = ReturnType<typeof createCurrentUsageActor>;

export const getCurrentPlanActor = (id: SystemKey) => {
  return actorSystem.get<Actor>(id);
};
