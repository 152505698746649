import { createMachine } from "xstate";
import type { OutputEvent as EpicEvent } from "./epic";

type Context = {
  usage: {
    used: number;
    allowed: number;
  };
};

// A union of all events externally sent to the actor
type RefEvent =
  | { type: "CURRENT_PLAN.FETCH" }
  | { type: "GLOBAL.QUERY_RUN.FINISHED" | "GLOBAL.QUERY_RUN_NEW.COMPLETED" };
type Event = RefEvent | EpicEvent;

// All dependencies necessary for initializing the actor
export interface Deps {}

// They key pattern that these actors can be referenced by
// If only one actor of this type will exist, you can remove the -${string}
export type SystemKey = `currentPlan-${string}`;

export const createCurrentPlanMachine = (id: SystemKey, deps: Deps) => {
  return createMachine({
    /** @xstate-layout N4IgpgJg5mDOIC5QAoC2BDAxgCwJYDswBKAOlwgBswBiAYQFUAlRgUQDkAVAfQAUAZAIJsSAMRYdaACQDaABgC6iUAAcA9rFwAXXKvxKQAD0QBGYwBYSZgBxmAzFaunbAVgBMxgJyuANCACeiK4A7BZmsgBsrmZBUdGybmYAvom+aFh4hKTkVHRMrJy8gsIsPACStCQA4uKFQlwAyvS0tCz19XKKSCBqGtq6+kYIZj7+JkFBJB7OtsauHrIeQbK2XkHJqRg4BMRklDQMzOzc-EIkJeVVNSdsXCICpXxMLB36PVo6el2D0Za2IR4zDyOBYAsy+AIIRwkZyyWHGBZmAFBWyuZzJFIgfCqCBwfRpLaZV7qd79L6IAC04XBFPC6xA+IyO2yYCJvQ+AxMVgmi3CziWVmckSCs2pCBWshIkQcHnC4WGf2MtnRiSAA */
    id,
    tsTypes: {} as import("./machine.typegen").Typegen0,
    predictableActionArguments: true,
    schema: {
      context: {} as Context,
      events: {} as Event,
    },
    initial: "idle",
    context: {
      usage: {
        used: 0,
        allowed: 1,
      },
    },
    states: {
      idle: {
        entry: ["fetchPlanEpic"],
        on: {
          "CURRENT_PLAN.FETCH": {
            actions: ["fetchPlanEpic"],
          },
          "GLOBAL.QUERY_RUN.FINISHED": {
            actions: ["fetchPlanEpic"],
          },
          "GLOBAL.QUERY_RUN_NEW.COMPLETED": {
            actions: ["fetchPlanEpic"],
          },
          "CURRENT_PLAN.EPIC.FETCH_SUCCESS": {
            actions: ["handleGetPlanSuccess"],
          },
          "CURRENT_PLAN.EPIC.FETCH_FAILURE": {
            actions: ["handleGetPlanFailure"],
          },
        },
      },
    },
  });
};

export type Options = Parameters<ReturnType<typeof createCurrentPlanMachine>["withConfig"]>["0"];
