import type { Team } from "@fscrypto/domain/team";
import { Button, LogoIcon, NavigationMenu, Text } from "@fscrypto/ui";
import { SiDiscord, SiX } from "@icons-pack/react-simple-icons";
import { Link, useLocation, useParams } from "@remix-run/react";
import { $path } from "remix-routes";
import { HeaderPlan } from "~/features/current-usage/ui/header-usage";
import { useCurrentUser } from "~/features/current-user";
import { DefaultWarehouseSelect } from "~/features/current-user/ui/default-warehouse-select";
import { RewardDrawer } from "~/features/earn/components/rewards-drawer";
import { track } from "~/features/home/tracking";
import { ProfileManagerContainer } from "~/features/profile-manager";
import { AddWalletModal } from "~/features/wallet-management/modal-add";
import { DATA_ACCESS, EARN, RESOURCES } from "../utils/shell-values";
import { RewardDrawerButtonDesktop, RewardDrawerButtonMobile } from "./reward-drawer-button";
import { ShellNavMobile } from "./shell-mobile";

const itemStyle = "text-muted hover:bg-stroke/5 flex h-auto flex-col items-start rounded-lg p-2 text-xs inline-block";

const ListItem = ({ title, to, children, ...props }: { title: string; children?: React.ReactNode; to: string }) => {
  if (title === "Flipside Pro") return null;
  return (
    <li>
      <NavigationMenu.Link asChild>
        <Link to={to} className={itemStyle} {...props}>
          <Text size="sm" weight="medium" color="contrast">
            {title}
          </Text>
          {children ? (
            <Text size="xs" color="muted">
              {children}
            </Text>
          ) : null}
        </Link>
      </NavigationMenu.Link>
    </li>
  );
};

const ListItemExternal = ({
  title,
  href,
  children,
  ...props
}: {
  title: string;
  children?: React.ReactNode;
  href: string;
}) => {
  if (title === "Flipside Pro") return null;
  return (
    <li>
      <NavigationMenu.Link asChild>
        <a href={href} className={itemStyle} {...props} target="_blank" rel="noreferrer">
          <Text size="sm" weight="medium" color="contrast">
            {title}
          </Text>
          {children ? (
            <Text size="xs" color="muted">
              {children}
            </Text>
          ) : null}
        </a>
      </NavigationMenu.Link>
    </li>
  );
};

export const Shell = ({ children, teams }: { children: React.ReactNode; teams: Team[] }) => {
  const { currentUser } = useCurrentUser();
  const { pathname } = useLocation();
  const { ecosystem } = useParams();
  const isStudioPath =
    pathname.startsWith("/edit") || pathname.startsWith("/studio") || pathname.startsWith("/dashboards");

  return (
    <div className="relative flex min-h-screen flex-col">
      <header className="bg-muted sticky top-0 z-40 w-full whitespace-nowrap" data-testid="shell-nav-bar">
        <div className="items-center px-4 md:flex md:h-[54px]">
          {/* Left */}
          <div className="flex h-12 items-center justify-between md:h-auto">
            <div className="flex items-center space-x-2">
              <ShellNavMobile />
              <Link
                to="/"
                className="flex items-center space-x-2 text-lg dark:text-white"
                onClick={() => track("Click Home")}
              >
                <LogoIcon className="h-6 dark:fill-white" />
                <span className="hidden text-lg font-medium md:block">flipside</span>
              </Link>
            </div>
            <div className="flex h-10 items-center md:hidden">
              {currentUser && <RewardDrawerButtonMobile />}
              <ProfileManagerContainer teams={teams} />
            </div>
          </div>

          {/* Center */}
          <nav className="hidden items-center space-x-4 px-8 md:flex">
            <NavigationMenu.Root>
              <NavigationMenu.List>
                <NavigationMenu.Item>
                  <NavigationMenu.Trigger>
                    Data <NavigationMenu.Caret />
                  </NavigationMenu.Trigger>
                  <NavigationMenu.Content>
                    <ul className="list-none space-y-1 sm:w-[250px]">
                      {DATA_ACCESS.map((product) => (
                        <ListItem key={product.to} to={product.to} title={product.title}>
                          {product.description}
                        </ListItem>
                      ))}
                    </ul>
                  </NavigationMenu.Content>
                </NavigationMenu.Item>

                <NavigationMenu.Item>
                  <NavigationMenu.NavLink asChild>
                    <Link to={$path("/insights/dashboards")}>Insights</Link>
                  </NavigationMenu.NavLink>
                </NavigationMenu.Item>

                <NavigationMenu.Item>
                  <NavigationMenu.Trigger>
                    Earn <NavigationMenu.Caret />
                  </NavigationMenu.Trigger>
                  <NavigationMenu.Content>
                    <ul className="list-none space-y-1 sm:w-[250px]">
                      {EARN.map((earn) => (
                        <ListItem key={earn.title} to={earn.to(ecosystem)} title={earn.title}>
                          {earn.description}
                        </ListItem>
                      ))}
                    </ul>
                  </NavigationMenu.Content>
                </NavigationMenu.Item>

                <NavigationMenu.Item>
                  <NavigationMenu.Trigger>
                    Resources <NavigationMenu.Caret />
                  </NavigationMenu.Trigger>
                  <NavigationMenu.Content>
                    <ul className="list-none space-y-1 sm:w-[200px]">
                      {RESOURCES.map((resource) => (
                        <ListItemExternal key={resource.href} href={resource.href} title={resource.title} />
                      ))}
                    </ul>
                  </NavigationMenu.Content>
                </NavigationMenu.Item>

                <NavigationMenu.Item>
                  <NavigationMenu.NavLink asChild>
                    <Link to={$path("/partnerships")}>Partnerships</Link>
                  </NavigationMenu.NavLink>
                </NavigationMenu.Item>
              </NavigationMenu.List>
            </NavigationMenu.Root>
            <a
              href="https://twitter.com/flipsidecrypto"
              target="_blank"
              rel="noreferrer"
              className="text-zinc-400"
              onClick={() => track(`Click Twitter`)}
              aria-label={`Open Twitter in new tab`}
            >
              <SiX className="size-4" />
            </a>
            <a
              href="https://discord.com/invite/ZmU3jQuu6W"
              target="_blank"
              rel="noreferrer"
              className="text-zinc-400"
              onClick={() => track(`Click Discord`)}
              aria-label={`Open Discord in new tab`}
            >
              <SiDiscord className="size-4" />
            </a>
          </nav>

          {/* Right */}
          <div className="relative ml-auto hidden items-center justify-end space-x-3 md:flex">
            {currentUser && !isStudioPath && (
              <Button variant="special" size="sm" asChild>
                <Link to="/edit">Launch Studio</Link>
              </Button>
            )}

            {currentUser && isStudioPath && <HeaderPlan />}
            {currentUser && isStudioPath && <DefaultWarehouseSelect />}
            {currentUser && isStudioPath && <div className="w-6"></div>}
            {currentUser && <RewardDrawerButtonDesktop />}

            <ProfileManagerContainer teams={teams} />
          </div>
        </div>
      </header>
      <main className="relative flex h-full flex-1 flex-col">{children}</main>
      <AddWalletModal />
      <RewardDrawer userTeams={teams} />
    </div>
  );
};
