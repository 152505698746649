import { HttpClient, Client } from "@fscrypto/http";
import { $path } from "remix-routes";
import { CurrentPlanUsageLoader } from "~/routes/api/plan-usage";

class ClientCurrentPlan {
  constructor(private readonly client: HttpClient) {
    this.client = client;
  }

  async getCurrentPlanUsage(): Promise<CurrentPlanUsageLoader> {
    return this.client.get($path("/api/plan-usage"));
  }
}

export const currentPlanClient = new ClientCurrentPlan(new Client());
