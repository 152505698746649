import { cva } from "class-variance-authority";

const root = cva(`relative z-[1] flex justify-center`);

const list = cva(`flex items-center space-x-1`);

const item = cva(
  `flex items-center text-sm antialiased font-medium text-content h-8 rounded-md px-3 hover:bg-muted-foreground/10 data-[state=open]:bg-muted-foreground/10 transition-colors`,
);

const viewport = cva([
  `flex rounded-lg ring-stroke/5 ring-1 shadow shadow-stroke/10 relative origin-[top_center] w-full bg-background overflow-hidden`,
  `h-[var(--radix-navigation-menu-viewport-height)] sm:w-[var(--radix-navigation-menu-viewport-width)]`,
]);

const content = cva([
  `absolute top-full mt-1 p-3`,
  `bg-popover rounded-lg ring-stroke/50 ring-1 shadow shadow-black/10`,
  // `data-[motion=from-start]:animate-in data-[motion=from-start]:slide-in-from-left data-[motion=from-start]:fade-in`,
  // `data-[motion=from-end]:animate-in data-[motion=from-end]:slide-in-from-right data-[motion=from-end]:fade-in`,
  // `data-[motion=to-start]:animate-out data-[motion=to-start]:slide-out-to-left data-[motion=to-start]:fade-out`,
  // `data-[motion=to-end]:animate-out data-[motion=to-end]:slide-out-to-right data-[motion=to-end]:fade-out`,
]);

export const navigationMenu = { root, list, item, content, viewport };
