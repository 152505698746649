import { wallet } from "@fscrypto/domain";
import { TeamInvitation } from "@fscrypto/domain/team-invitation";
import { Avatar, Button, Dropdown, Flex, Text } from "@fscrypto/ui";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@fscrypto/ui/accordion";
import { Skeleton } from "@fscrypto/ui/skeleton";
import { NavLink, useLocation, useNavigate, useSubmit } from "@remix-run/react";
import { sortBy } from "lodash-es";
import {
  CheckIcon,
  ChevronDown,
  LogOutIcon,
  MoonIcon,
  PlusCircleIcon,
  SettingsIcon,
  SunIcon,
  UserIcon,
  UsersIcon,
  XIcon,
} from "lucide-react";
import { useState } from "react";
import { $path } from "remix-routes";
import { useCurrentUser } from "~/features/current-user";
import { ProjectIcon } from "~/features/earn/components/project-icon";
import { useAddWalletModal } from "~/features/wallet-management/state/use-add-wallet-modal";
import { useWalletAddress, useWallets } from "~/features/wallet-management/state/use-wallet";
import { useEvent } from "~/state/events";
import { useUserStateMachine } from "~/state/machines/user-state/user-state";
import { truncateAddress } from "~/utils/helpers";
import { tracking } from "~/utils/tracking";
import { track } from "..";
import { isUser } from "../util/is-user";
import { UserProfile } from "../util/resolve-current-profile";

interface RootProps {
  children: React.ReactNode;
  trigger: React.ReactNode;
}

const Root = ({ children, trigger }: RootProps) => (
  <Dropdown.Root>
    <Dropdown.Trigger
      onClick={() => {
        track(`Expand Account Dropdown `);
      }}
    >
      {trigger}
    </Dropdown.Trigger>
    <Dropdown.Content className="z-50 w-[300px]" side="bottom" align="end" alignOffset={0}>
      {children}
    </Dropdown.Content>
  </Dropdown.Root>
);

const Trigger = ({ currentProfile }: { currentProfile?: UserProfile }) => {
  const username = isUser(currentProfile) ? currentProfile.username : currentProfile?.name;
  return (
    <div className="flex max-w-[220px] items-center space-x-2 text-sm dark:text-zinc-400">
      <Avatar size="sm" src={currentProfile?.avatarUrl} />
      <span className="hidden flex-1 overflow-hidden truncate text-left lg:block">{username}</span>
      <ChevronDown className="size-4" />
    </div>
  );
};

const AddWallet = () => {
  const addWalletModalState = useAddWalletModal();
  const walletState = useWallets();
  return (
    <Dropdown.Item noHover asChild>
      <div
        className="bg-primary mt-5 flex w-full cursor-pointer justify-center text-white"
        onClick={() => {
          tracking("add_address_modal", "Profile Manager");
          addWalletModalState?.setModalOpen();
        }}
      >
        {(walletState?.verifiedWallets?.length ?? 0) > 0 ? "Connect New Wallet" : "Add A Wallet"}
      </div>
    </Dropdown.Item>
  );
};

const Wallets = () => {
  const { walletsByChain, defaultWalletByChain } = useWallets();
  const chains = sortBy(Object.keys(walletsByChain));

  return (
    <Accordion type="multiple" className="flex flex-col gap-y-2">
      {chains.map((chain) => {
        const defaultWallet: wallet.Address | undefined = defaultWalletByChain[chain];
        const otherWallets = walletsByChain[chain].filter((w) => w.id !== defaultWallet?.id);
        return (
          <AccordionItem value={chain} className="rounded-lg border" key={chain}>
            <AccordionTrigger className="px-2" caretPosition="left">
              <AccordionTriggerContent chain={chain} defaultWallet={defaultWallet} />
            </AccordionTrigger>
            <AccordionContent>
              <div className="space-y-2">
                {otherWallets.map((a) => (
                  <SelectableWalletAddress key={a.id} walletAddress={a} />
                ))}
              </div>
            </AccordionContent>
          </AccordionItem>
        );
      })}
    </Accordion>
  );
};

const AccordionTriggerContent = ({ chain, defaultWallet }: { chain: string; defaultWallet?: wallet.Address }) => {
  const [isLoading, setIsLoading] = useState(false);

  useEvent("GLOBAL.WALLET.UPSERT_IN_PROGRESS", (e) => {
    const isSameChain = e.payload.addressUpsert.chain === chain;
    const isSettingDefault = e.payload.addressUpsert.isDefault;
    if (isSameChain && isSettingDefault) {
      setIsLoading(true);
    }
  });

  useEvent("GLOBAL.WALLET.UPSERT_SUCCESS", (e) => {
    const isSameChain = e.payload.newAddress.chain === chain;
    const isSettingDefault = e.payload.newAddress.isDefault;
    if (isSameChain && isSettingDefault) {
      setIsLoading(false);
    }
  });

  return (
    <Flex className="gap-x-2 pl-2">
      <ProjectIcon iconFileName={chain} className="h-6 w-6 rounded-lg" />
      {isLoading ? (
        <Skeleton className="h-5 w-full" />
      ) : (
        <>
          {defaultWallet ? (
            <span className="break-all text-left">
              {defaultWallet?.nickname
                ? defaultWallet.nickname
                : truncateAddress(defaultWallet.address, { left: 8, right: 8 })}
            </span>
          ) : (
            <span className="italic">Choose a wallet</span>
          )}
        </>
      )}
    </Flex>
  );
};

const SelectableWalletAddress = ({ walletAddress }: { walletAddress: wallet.Address }) => {
  const { setDefault } = useWalletAddress(walletAddress.id);
  return (
    <div className="hover:bg-muted w-full cursor-pointer py-1" onClick={setDefault}>
      <p className="pl-14">
        {walletAddress.nickname
          ? walletAddress.nickname
          : truncateAddress(walletAddress.address, { left: 8, right: 8 })}
      </p>
    </div>
  );
};

const Logout = () => (
  <a href="/auth/auth0/logout" onClick={() => track(`logout`)} className="text-warning flex justify-end p-2">
    <LogOutIcon className="mr-2 h-5 w-5 " />
    Logout
  </a>
);

const Divider = ({ title }: { title: string }) => (
  <>
    <Dropdown.Separator />
    <Dropdown.Label>{title}</Dropdown.Label>
  </>
);

const CreateTeamAccount = () => (
  <Dropdown.Item asChild>
    <NavLink to="/teams/create" onClick={() => track(`Create New Team`)}>
      <PlusCircleIcon className="text-primary mr-2 h-6 w-6" />
      <Text size="sm">Create Team Account</Text>
    </NavLink>
  </Dropdown.Item>
);

const ProfileButtons = ({ children }: { children: React.ReactNode }) => (
  <div className="flex justify-center gap-x-3 pb-3">{children}</div>
);

const ProfileButton = ({ to, isTeam }: { to: string; isTeam?: boolean }) => (
  <Button variant="secondary" size="sm" asChild iconOnly rounded>
    <a href={to}>{isTeam ? <UsersIcon className="size-4" /> : <UserIcon className="size-4" />}</a>
  </Button>
);

const SettingsButton = () => {
  return (
    <Button variant="secondary" size="sm" asChild iconOnly rounded>
      <a href={"/settings/plan"}>
        <SettingsIcon className="size-4" />
      </a>
    </Button>
  );
};

const DarkModeButton = () => {
  const { theme, setTheme } = useUserStateMachine();
  return (
    <Button
      variant="secondary"
      size="sm"
      asChild
      iconOnly
      rounded
      onClick={() => {
        setTheme(theme === "light" ? "dark" : "light");
        track(theme === "light" ? "set_theme-dark" : "set_theme_light");
      }}
    >
      <div>{theme === "light" ? <MoonIcon className="size-4" /> : <SunIcon className="size-4" />}</div>
    </Button>
  );
};

const LoggedOut = () => {
  const location = useLocation();
  const submit = useSubmit();

  const baseUrl = typeof window === "undefined" ? process.env.BASE_URL : window.ENV.BASE_URL;
  const redirectUrl = `${baseUrl}${location.pathname}${location.search}${location.hash}`;

  const handleAuthClick = (isRegister: boolean) => {
    const event = isRegister ? "Click Signup" : "Click Login";
    track(event);
    submit(null, {
      method: "post",
      action: $path("/auth/auth0", { screen_hint: isRegister ? "signup" : "", redirectUrl }),
    });
  };

  return (
    <div className="flex items-center space-x-1">
      <Button variant="text" size="sm" onClick={() => handleAuthClick(false)}>
        Log in
      </Button>
      <Button variant="special" size="sm" onClick={() => handleAuthClick(true)}>
        Sign up for free
      </Button>
    </div>
  );
};

const CurrentProfile = ({ currentProfile }: { currentProfile: UserProfile }) => {
  const username = isUser(currentProfile) ? currentProfile.username : currentProfile?.name;
  const url = isUser(currentProfile) ? null : currentProfile.url;
  return (
    <div className="flex flex-col items-center justify-center gap-y-3 p-6">
      <Avatar src={currentProfile.avatarUrl} size="xl" />
      <Text size="2xl" className="w-full truncate px-2 text-center">
        {username}
      </Text>
      {url && (
        <a href={url} target="_blank" rel="noreferrer">
          <Text size="lg" color="muted">
            {url}
          </Text>
        </a>
      )}
    </div>
  );
};

const PendingInvite = ({
  invite,
  onAccept,
  onDecline,
}: {
  invite: TeamInvitation;
  onAccept: (invite: TeamInvitation) => void;
  onDecline: (id: string) => void;
}) => (
  <Dropdown.Item key={invite.id} asChild className="overflow-hidden rounded-lg">
    <div className="flex items-center justify-between" onClick={(e) => e.preventDefault()}>
      <div className="flex items-center">
        <Avatar size="sm" src={""} />
        <Text className="ml-2 truncate" size="sm">
          {invite.team?.name}
        </Text>
      </div>
      <div className="flex gap-x-1">
        <Button
          iconOnly
          variant="secondary"
          size="xs"
          onClick={() => {
            onDecline?.(invite.id);
            track("Decline Invite");
          }}
        >
          <XIcon className="size-4" />
        </Button>
        <Button
          iconOnly
          variant="primary"
          size="xs"
          onClick={() => {
            track("Accept Invite");
            onAccept?.(invite);
          }}
        >
          <CheckIcon className="size-4" />
        </Button>
      </div>
    </div>
  </Dropdown.Item>
);

const OtherAccount = ({ profile }: { profile: UserProfile }) => {
  const navigate = useNavigate();
  const username = isUser(profile) ? profile.username : profile?.name;
  const queryParams = isUser(profile) ? "" : `&team-slug=${profile.slug}`;
  const { setCurrentProfileId } = useCurrentUser();
  return (
    <Dropdown.Item asChild className="overflow-hidden rounded-lg">
      <div
        className="flex items-center justify-between"
        onClick={() => {
          const currentUrl = window.location.href;
          setCurrentProfileId?.(profile.profileId!);
          navigate(`/teams-redirect/${profile.profileId}?redirect=${encodeURIComponent(currentUrl)}${queryParams}`);
          track("Select Team Account", { profileId: profile.profileId! });
        }}
      >
        <div className="flex items-center overflow-hidden">
          <Avatar size="sm" src={profile.avatarUrl} className="flex-shrink-0" />
          <Text className="ml-2 flex-1 truncate" size="sm">
            {username}{" "}
            <Text size="sm" color="muted">
              {isUser(profile) ? "(You)" : ""}
            </Text>
          </Text>
        </div>
      </div>
    </Dropdown.Item>
  );
};

export const ProfileManager = {
  Root,
  Logout,
  Trigger,
  Divider,
  PendingInvite,
  CreateTeamAccount,
  LoggedOut,
  OtherAccount,
  CurrentProfile,
  ProfileButtons,
  ProfileButton,
  SettingsButton,
  DarkModeButton,
  AddWallet,
  Wallets,
};
