import { useCurrentUser } from "~/features/current-user";
import { useCurrentUsage } from "../state";
import { Button, LogoIcon, Progress, Tooltip } from "@fscrypto/ui";
import { SettingsIcon } from "lucide-react";
import { Link } from "@remix-run/react";
import { plan } from "@fscrypto/domain";

export function HeaderPlan() {
  const { usage } = useCurrentUsage();
  const user = useCurrentUser();

  if (user.userPlan && plan.isFree(user.userPlan.plan.code)) {
    return (
      <Button variant="special" size="sm" asChild>
        <Link to="/pricing">
          <LogoIcon className="mr-2 h-3 w-3" />
          Upgrade
        </Link>
      </Button>
    );
  }

  // calculate percent used
  const percentUsed = ((usage?.used ?? 0) / (usage?.allowed ?? 1)) * 100;

  return (
    <div className="mt-1 flex items-center space-x-3">
      <Tooltip
        content={`${Math.round(usage?.used ?? 0)}/${usage?.allowed ?? 0} premium query seconds used`}
        side="bottom"
        wrap
      >
        <div className="md:min-w-24 justify-between md:flex md:flex-col">
          <h5 className="text-foreground flex items-center justify-between pb-1 text-sm font-medium leading-snug">
            {user.userPlan?.plan.name} &nbsp;
            <Link to="/settings/plan">
              <SettingsIcon className="size-4 text-muted-foreground" />
            </Link>
          </h5>
          <Progress value={percentUsed} size="sm" color={percentUsed >= 100 ? "warning" : "primary"} />
        </div>
      </Tooltip>
    </div>
  );
}
