import { Team } from "@fscrypto/domain/team";
import { useProfileManager } from "./state";
import { ProfileManager } from "./ui/profile-manager";
import { useCurrentProfileData } from "./util/use-current-profile-data";

export const ProfileManagerContainer = ({ teams: serverTeams }: { teams: Team[] }) => {
  const { invites, declineInvitation, acceptInvitation, teams } = useProfileManager(serverTeams);
  const { currentProfile, url, otherAccounts, isTeam } = useCurrentProfileData(teams);
  if (!currentProfile) return <ProfileManager.LoggedOut />;
  return (
    <ProfileManager.Root trigger={<ProfileManager.Trigger currentProfile={currentProfile} />}>
      <ProfileManager.Logout />
      <ProfileManager.CurrentProfile currentProfile={currentProfile} />
      <ProfileManager.ProfileButtons>
        <ProfileManager.ProfileButton to={url} isTeam={isTeam} />
        <ProfileManager.SettingsButton />
        <ProfileManager.DarkModeButton />
      </ProfileManager.ProfileButtons>
      <ProfileManager.Divider title="Team Accounts" />
      {otherAccounts?.map((account) => (
        <ProfileManager.OtherAccount profile={account} key={account.id} />
      ))}
      {(invites?.length ?? 0) > 0 && <ProfileManager.Divider title="Pending Invitations" />}
      {invites?.map((invite) => (
        <ProfileManager.PendingInvite
          invite={invite}
          onAccept={acceptInvitation}
          onDecline={declineInvitation}
          key={invite.id}
        />
      ))}
      <ProfileManager.CreateTeamAccount />
      <ProfileManager.Divider title="Quest Addresses" />
      <ProfileManager.Wallets />
      <ProfileManager.AddWallet />
    </ProfileManager.Root>
  );
};
